// JavaScript
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD_xPzXjmzC3ibwyY12SrKjBb8BHDWF0Ss",
  authDomain: "dudesgood-404.firebaseapp.com",
  projectId: "dudesgood-404",
  storageBucket: "dudesgood-404.appspot.com",
  messagingSenderId: "761203638278",
  appId: "1:761203638278:web:30947c3c1da39af76a8387",
  measurementId: "G-JE3FF96R4J"
};


// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export {db}