import React, { Fragment, useState, useEffect } from "react";

import Hero from "../components/Hero";
import Lobby from "../components/Lobby";
import Content from "../components/Content";
import { useAuth0 } from "@auth0/auth0-react";
import { Progress } from "reactstrap";

const Home = () => {
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  useEffect(() => {
    // Use a setTimeout to hide the loading screen after 10 seconds (10000 milliseconds)
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 4000);

    // Clear the timer if the component unmounts (cleanup)
    // return () => clearTimeout(timer);
  }, []);
  
  const textAnimationStyle = {
    animation: isLoading ? 'bounce 0.5s infinite alternate' : 'none',
  };
  const dotAnimationStyle = {
    animation: isLoading ? 'blink 1s infinite' : 'none',
  };
  const progressBarAnimationStyle = {
    animation: isLoading ? 'progressBarAnimation 2s linear infinite' : 'none',
  };

  return (
  <Fragment>
    {isLoading ? (
      <div className="loading-screen">
        <h1 style={textAnimationStyle}>Loading...<span className="whitec"> <br />Your cards are practicing their epic entrances!</span><span style={dotAnimationStyle}>...</span></h1>
        {/* <Progress
          value={100}
          color="warning"
          className="thinner-progress-bar"
          style={{ width: '100px', ...progressBarAnimationStyle }}
        /> */}
      </div>
    ) : (
      <div className="main-content">
       <Hero />
      </div>
    )}
  </Fragment>
)};

export default Home;
